app.directive 'compiler', ($compile) ->
    {
        restrict: 'A'
        replace: true
        link: (scope, ele, attrs) ->
            scope.$watch attrs.compiler, (html) ->
                ele.html html
                $compile(ele.contents()) scope
                return
            return

    }