app.controller 'UserAccountModalController', ($uibModalInstance, Api) ->

    @user = {}
    @errors = []

    @close = () ->
        $uibModalInstance.dismiss('cancel');

    @save = () ->

        @errors = []
        
        if @user.new_password != @user.new_password_repeat
            @errors = ['passwords_unequal']
            return

        Api.put 'user/' + @user.id, @user
        .then (data) =>
            if data.errors.length == 0
                $uibModalInstance.close()
                @errors = []
            else
                @errors = data.errors

    return