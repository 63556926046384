app.config ($stateProvider, $urlRouterProvider) ->

    $urlRouterProvider.otherwise '/'

    $stateProvider.state 'app',
        url: '/'
        resolve:
            context_translations: (TranslationService) ->
                return TranslationService.resolve 'context'
        views:
            '@':
                templateUrl: '/apps/apps'