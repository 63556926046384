app.service 'Api', ($rootScope, $http, $q) ->
    Api =
        resolve: (url) ->
            url = '/api/' + url
            return $http.get(url)
            .then (res) ->
                return res.data
        post: (url, params) ->
            url = '/api/' + url
            $q (resolve, reject) ->
                $http.post(url, params)
                .then((res) ->
                    resolve res.data
                , (res) ->
                    reject res
                )
        put: (url, params) ->
            url = '/api/' + url
            $q (resolve, reject) ->
                $http.put(url, params)
                .then((res) ->
                    resolve res.data
                , (res) ->
                    reject res
                )
    return Api