app.service 'TranslationService', ($rootScope, $http) ->
    srv =
        resolve: (key) ->
            if !$rootScope.translations?
                $rootScope.translations = {}

            if $rootScope.translations[key]?
                return $rootScope.translations[key]
            else
                return $http.get('/translations/' + key).then (res) ->
                    return $rootScope.translations[key] = res.data

        trans: (key) ->
            _key = key.split '.'
            return $rootScope.translations[_key[0]][_key[1]] || _key[1]


    return srv