app.controller 'AdminUserCreateController', (Api, $state) ->

    @user = {}

    @store = () ->

        if @user.password != @user.password_repeat
            alert 'Passwörter stimmen nicht überein!'
            return

        Api.post 'admin/user', @user
        .then () ->
            $state.go 'app.admin.user.index'
            return

    return