app.controller 'AdminUserEditController', (user, Api, $state) ->

    @user = user

    @update = () ->

        if @changePassword && @user.password != @user.password_repeat
            alert 'Passwörter stimmen nicht überein!'
            return

        Api.put 'admin/user/' + @user.id, @user
        .then () ->
            $state.go 'app.admin.user.index'
            return

    return