app.config ($stateProvider) ->

    $stateProvider.state 'app.admin.user.edit',
        url: '/edit/{user}'
        resolve:
            user: (Api, $stateParams) ->
                Api.resolve 'admin/user/' + $stateParams.user
        views:
            '@app.admin.user':
                templateUrl: '/view/Admin.User.Edit.edit'
                controller: 'AdminUserEditController'
                controllerAs: 'UserEdit'