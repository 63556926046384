app.run ($rootScope, $window, $state, $stateParams, $location, $sce, $timeout, $interval) ->
    $rootScope.$state = $state
    $rootScope.$stateParams = $stateParams
    $rootScope.$window = $window
    $rootScope.$location = $location

    $rootScope.splash = on

    $rootScope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
        $rootScope.splash = off
        $rootScope.previousState = fromState.name

    $rootScope.nl2br = (str, is_xhtml) ->
        breakTag = if is_xhtml or typeof is_xhtml == 'undefined' then '<br />' else '<br>'
        (str + '').replace /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'

    return