app.config ($stateProvider) ->

    $stateProvider.state 'app.admin.user.show',
        url: '/show/{user}'
        resolve:
            user: (Api, $stateParams) ->
                Api.resolve 'admin/user/' + $stateParams.user
        views:
            '@app.admin.user':
                templateUrl: '/view/Admin.User.Show.show'
                controller: 'AdminUserShowController'
                controllerAs: 'UserShow'