app.config ($stateProvider) ->

    $stateProvider.state 'app.admin.user.index',
        url: '/user/index'
        resolve:
            users: (Api) ->
                Api.resolve 'admin/user'
        views:
            '@app.admin.user':
                templateUrl: '/view/Admin.User.Index.index'
                controller: 'AdminUserIndexController'
                controllerAs: 'UserIndex'