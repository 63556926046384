app.controller 'AppsCashNavigatorController', ($uibModal) ->

    @openModal = () ->

        $uibModal.open
            controller: 'AppsCashNavigatorModalController as AppsCashNavigatorModal'
            templateUrl: '/view/Apps.CashNavigator.init?t=' + (new Date()).getTime(),
            size: 'sm'
            windowClass: 'hmodal-info'

        return

    return