app.controller 'UserAccountController', ($uibModal) ->

    @openModal = () ->

        $uibModal.open
            controller: 'UserAccountModalController as UserAccountModal'
            templateUrl: '/view/User.Account.account?t=' + (new Date()).getTime(),
            size: 'sm'
            windowClass: 'hmodal-info'

        return

    return